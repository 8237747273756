.center_div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* height: 80vh; */
}
.left_side{
    width: 100%;
    height: 90vh;

}
.text-area{
    width: 100%;
    height: 100%;
resize: none;
    padding: 10px;
    outline: none;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    border: none;
}
.right_side{
    width: 100%;
        height: 90vh;
    overflow-y: auto;
    padding: 10px;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}